import { Typography } from "@mui/material";
import { BeachData } from "../../data/interfaces/BeachData";
import {
  capitalizeFirstLetter,
  getFirstNonNull,
  isOpenBeach,
} from "../../utils/helpers";

interface BeachMarkerDetailProps {
  location: BeachData;
}

const BeachMarkerDetail = (props: BeachMarkerDetailProps) => {
  const { location } = props;

  const beachDisplayName = getFirstNonNull(
    location.dfwName,
    location.beachName,
    "Unknown Beach"
  );
  const finalStatus = location.finalStatus;
  const statusImage = isOpenBeach(finalStatus)
    ? { href: "/beachopen.png", alt: "Open Beach" }
    : { href: "/beachclosed.png", alt: "Closed Beach" };

  return (
    <div>
      <Typography sx={{ margin: "5px" }}>
        <a href={location.wdfwLink} target="_blank" rel="noreferrer">
          {capitalizeFirstLetter(beachDisplayName)}
        </a>{" "}
        <img
          src={statusImage.href}
          alt={statusImage.alt}
          width="30"
          height="30"
        />
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          {location.description}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          {location.beachComment && (
            <div>
              <strong>Comment:</strong> {location.beachComment}
            </div>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          {location.waStateParkLink && (
            <a href={location.waStateParkLink} target="_blank" rel="noreferrer">
              WA State Park Link
            </a>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          Final Status:{" "}
          <a
            href="https://doh.wa.gov/community-and-environment/shellfish/growing-areas#RecShellClass"
            target="_blank"
            rel="noreferrer"
          >
            {finalStatus}
          </a>
        </Typography>
      </Typography>
    </div>
  );
};

export default BeachMarkerDetail;
