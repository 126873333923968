import { Box, Typography } from "@mui/material";
import TideForm from "../components/TideForm";
import PageContainer from "../layouts/PageContainer";

const Tide = () => {
  return (
    <PageContainer title="Tidal Graph">
      <Box>
        <Typography sx={{ fontWeight: "light", m: 1 }}>
          Using NOAA Tidal Prediction API set to{" "}
          <a
            href="https://tidesandcurrents.noaa.gov/stationhome.html?id=9447130"
            target="_blank"
            rel="noreferrer"
          >
            Seattle station - Station ID 9447130
          </a>{" "}
          , this gives a general low/high tide prediction for a date range from
          January 1st, 2023 to December 31st, 2023. You will find that this
          matches the information provided by{" "}
          <a
            href="https://wdfw.wa.gov/sites/default/files/fishing/shellfishing/WDFWBestClamOysterHarvestTides.pdf"
            target="_blank"
            rel="noreferrer"
          >
            WDFD 2023 best clam and oyster harvest tides chart
          </a>
          .
        </Typography>
      </Box>
      <Box>
        <Box sx={{ m: 3 }}>
          <TideForm />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default Tide;
