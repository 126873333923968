import { useMemo, useState } from "react";
import BaseMap from "./BaseMap";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { cityCoordinates } from "../../utils/constants";
import { isOpenBeach } from "../../utils/helpers";
import { BeachData } from "../../data/interfaces/BeachData";
import BeachMarkerDetail from "./BeachMarkerDetail";

interface BeachMapProps {
  locations: BeachData[];
  openOnly: boolean;
}

const BeachMap = (props: BeachMapProps) => {
  const { locations, openOnly } = props;

  const [model] = useState({
    zoom: 10,
    center: cityCoordinates.SEATTLE,
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState<BeachData>();

  const handleMarkerClick = (
    id: string,
    lat: number,
    lng: number,
    location: BeachData
  ) => {
    setActiveMarker(id);
    setSelectedLocation(location);
  };

  const filteredLocations = useMemo(() => {
    return openOnly
      ? locations.filter((location) => isOpenBeach(location.finalStatus))
      : locations;
  }, [locations, openOnly]);

  return (
    <BaseMap model={model}>
      {filteredLocations.map((location) => {
        const { beachId, midx, midy } = location;
        const id = beachId;
        const lat = Number(midy);
        const long = Number(midx);

        return (
          <MarkerF
            key={id}
            title={id}
            position={{
              lat: lat,
              lng: long,
            }}
            onClick={() => handleMarkerClick(id, lat, long, location)}
          >
            {activeMarker === id && (
              <InfoWindowF
                key={id}
                onCloseClick={() => {
                  setActiveMarker(false);
                }}
              >
                {selectedLocation && (
                  <BeachMarkerDetail location={selectedLocation} />
                )}
              </InfoWindowF>
            )}
          </MarkerF>
        );
      })}
    </BaseMap>
  );
};

export default BeachMap;
