export async function getBeachData() {
  try {
    const response = await fetch(
      "https://shellfishbuddy.s3.us-west-2.amazonaws.com/beach.json"
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch Beach data: ${error.message}`);
  }
}

export async function getTideData() {
  try {
    const response = await fetch(
      "https://shellfishbuddy.s3.us-west-2.amazonaws.com/tide.json"
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch Tide data: ${error.message}`);
  }
}
