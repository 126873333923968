import * as moment from "moment";

export const OPEN_STATUS = ["Open", "Conditionally Open"];
export const CLOSE_STATUS = ["Closed", "Unclassified"];

export const capitalizeFirstLetter = (name) => {
  return name.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
};

export const getFirstNonNull = (str1, str2, defaultValue) => {
  if (str1 !== null && str1 !== undefined && str1 !== " ") {
    return str1.trim();
  } else if (str2 !== null && str2 !== undefined && str2 !== " ") {
    return str2.trim();
  }
  return defaultValue;
};

export const isOpenBeach = (status) => {
  return OPEN_STATUS.includes(status);
};

export const isClosedBeach = (status) => {
  return CLOSE_STATUS.includes(status);
};

export const dateFormatter = (value, dateFormat) =>
  moment(value).format(dateFormat);
