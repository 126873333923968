import { Box, Typography } from "@mui/material";
import React from "react";
import PageContainer from "../layouts/PageContainer";

const Contact = () => {
  return (
    <PageContainer>
      <Typography sx={{ fontWeight: "light", m: 1 }}>
        We&apos;d love to hear from you!
      </Typography>
      <Box sx={{ height: "100vh" }}>
        <iframe
          title="Contact"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfzY3PfFq1MQg5mej3oIIel7L3f_Jk5NUSwT8HvIbRTK3IKOQ/viewform?embedded=true"
          style={{ width: "100%", height: "calc(100% - 3px)" }}
          referrerPolicy="no-referrer"
        >
          Loading…
        </iframe>
      </Box>
    </PageContainer>
  );
};

export default Contact;
