import { useEffect, useState } from "react";
import PageContainer from "../layouts/PageContainer";
import CrabbingInfo from "./Crabbing/CrabbingInfo";

import { Alert, Box, Typography } from "@mui/material";
import crab from "../data/crab.json";

const Crabbing = () => {
  const [crabData, setCrabData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    try {
      const fetchData = () => {
        setCrabData(crab.data);
      };
      fetchData();
    } catch (error) {
      setHasError(true);
    }
    setIsLoading(false);
  }, []);

  let dataLoaded = !isLoading && !hasError;

  return (
    <PageContainer title="Fave Crabbing📍">
      <Box>
        <Typography sx={{ fontWeight: "light", my: 1 }}>
          Crabbing is my favorite activity in summer! In this page you will find
          several crabbbing spots I personally go crabbing at and highly
          recommend. This page means to serve as a guidance for locations, tide
          and wind forecast. For official seasons and regulations, please always
          consult{" "}
          <a
            href="https://wdfw.wa.gov/fishing/shellfishing-regulations/crab"
            target="_blank"
            rel="noopener noreferrer"
          >
            WDFW Crab seasons and dates page
          </a>
          !
        </Typography>
        <Typography sx={{ fontWeight: "light", my: 1 }}>
          It is worth mentioning that I mostly use my paddleboard to paddle
          further out into the water to drop my crab cages. This tends to yield
          better results than dropping gears from the pier. I have done both and
          the results varied. Be patient, be prepared and just try out!
        </Typography>
        <Alert severity="warning" sx={{ fontWeight: "medium", my: 3 }}>
          If you decide to paddleboard out to crab, please take safety seriously
          and plan your adventure carefully by looking up tide schedule, wind
          forecast (I highly recommend to go out when Wind Speed is 5mph or
          less) and other safety measure. Please do pier crabbing if it is
          within your comfort zone!
        </Alert>
        {dataLoaded ? <CrabbingInfo locations={crabData} /> : null}
        {hasError ? <div>An error occurred while loading the data.</div> : null}
        {isLoading ? <div>Data is loading...</div> : null}
      </Box>
    </PageContainer>
  );
};

export default Crabbing;
