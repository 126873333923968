/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

interface BaseMapProps {
  model?: any;
  children?: any;
  height?: string;
  width?: string;
}

const BaseMap = (props: BaseMapProps) => {
  const { model, children, height, width } = props;

  const { isLoaded } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const adjustedHeight = height ? height : "80vh"; // Set the height based on a condition
  const adjustedWidth = width ? width : "100%"; // Set the width based on a condition

  const renderLoading = (adjustedHeight, adjustedWidth) => (
    <div style={{ height: adjustedHeight, width: adjustedWidth }}>
      Loading...
    </div>
  );

  const renderMap = (adjustedHeight, adjustedWidth) => (
    <div style={{ height: adjustedHeight, width: adjustedWidth }}>
      <GoogleMap
        zoom={model?.zoom}
        center={model?.center}
        onCenterChanged={model?.updatePosition}
        onZoomChanged={model?.updateZoom}
        onClick={model?.onClick}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
      >
        {children}
      </GoogleMap>
    </div>
  );

  return isLoaded
    ? renderMap(adjustedHeight, adjustedWidth)
    : renderLoading(adjustedHeight, adjustedWidth);
};

export default BaseMap;
