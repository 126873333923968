import {
  Box,
  Container,
  Stack,
  SxProps,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ReactNode } from "react";

interface PageContainerProps {
  title: string;
  sx: SxProps;
  children: ReactNode;
}

const theme = createTheme();
theme.typography.h3 = {
  fontSize: "1.9rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

const PageContainer = (props: PageContainerProps) => {
  const { title, sx, children } = props;

  return (
    <Container maxWidth="xl" sx={{ mt: 2, ...sx }}>
      <Stack spacing={2}>
        <ThemeProvider theme={theme}>
          <Typography variant="h3">{title}</Typography>
        </ThemeProvider>
        <Box>{children}</Box>
      </Stack>
    </Container>
  );
};

export default PageContainer;
