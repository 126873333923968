import moment from "moment-timezone";

export function getDateNoDash(date) {
  return date.replace(/-/g, "");
}

// Util checkin if a date is between startDate and endDate
// the date will have full timestamp such as "2023-01-01 01:40",
// while startDate and endDate will be more like '2023-05-31' and '2023-06-16'
export function isBetweenDate(date, startDate, endDate) {
  const formattedDate = moment.tz(date, "America/Los_Angeles");
  const formattedStartDate = moment.tz(startDate, "America/Los_Angeles");
  const formattedEndDate = moment.tz(endDate, "America/Los_Angeles");

  return (
    formattedDate.isSameOrAfter(formattedStartDate, "day") &&
    formattedDate.isSameOrBefore(formattedEndDate, "day")
  );
}
