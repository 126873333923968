import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  Map,
  Beach,
  Resources,
  Tide,
  Crabbing,
  Contact,
} from "./pages/_index";
import ResponsiveNavbar from "./layouts/ResponsiveNavbar";

const App = () => {
  return (
    <BrowserRouter>
      <ResponsiveNavbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/tide" element={<Tide />} />
        <Route path="/beach" element={<Beach />} />
        <Route path="/map" element={<Map />} />
        <Route path="/crab" element={<Crabbing />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
