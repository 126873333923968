import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import reportWebVitals from "./reportWebVitals";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <StrictMode>
      <App />
    </StrictMode>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
