import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@mui/material";
import { CrabData } from "../../data/interfaces/CrabData";

interface CrabbingLocationDetailProps {
  location: CrabData;
}

const CrabbingLocationDetail = (props: CrabbingLocationDetailProps) => {
  const { location } = props;

  return (
    <div>
      <Typography>
        {location.name}
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          <IconButton>
            <FontAwesomeIcon icon={faRoute} />
          </IconButton>
          {location.directionLink !== null && (
            <a href={location.directionLink} target="_blank" rel="noreferrer">
              Direction
            </a>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          {location.forecast !== null && (
            <a href={location.forecast} target="_blank" rel="noreferrer">
              Wind & Tide forecast from Windfinder
            </a>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          <b>Species found:</b> {location.species.join(", ")}
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ fontWeight: "light" }}>
          Marine Area:{" "}
          <a href={location.marineAreaLink} target="_blank" rel="noreferrer">
            {location.marineArea}
          </a>
        </Typography>
      </Typography>
    </div>
  );
};

export default CrabbingLocationDetail;
