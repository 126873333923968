import {
  Alert,
  Breakpoint,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import * as moment from "moment";
import { ReactNode, useCallback, useMemo, useState } from "react";
import useToggle from "../hooks/useToggle";
import TidalGraph from "../pages/Tide/TidalGraph";
import { getTideData } from "../utils/dataSourceUtil";
import { isBetweenDate } from "../utils/dateUtil";

interface TideFormProps {
  maxWidth: Breakpoint | false;
  title: ReactNode;
}

const TideForm = (props: TideFormProps) => {
  const [graphData, setGraphData] = useState(null);

  // Checkbox state
  const [lowtideOnly, setLowtideOnly] = useToggle(false);

  const handleLowtideOnlyCheckbox = (event) => {
    setLowtideOnly(event.target.checked);
  };

  // Calendar state
  const [startDate, setStartDate] = useState(moment(""));
  const [endDate, setEndDate] = useState(moment(""));
  const showDatePrompt = useMemo(
    () => !moment(startDate).isValid() || !moment(endDate).isValid(),
    [startDate, endDate]
  );

  const [alert, , setShowAlert] = useToggle(false);

  const handleChangeStartDate = useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleChangeEndDate = useCallback((date) => {
    setEndDate(date);
  }, []);

  const disableSubmit = showDatePrompt;

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      const fetchData = async () => {
        const response = await getTideData();
        const filteredData = response.filter((tide) =>
          isBetweenDate(tide.time, startDate, endDate)
        );
        setGraphData(filteredData);
      };
      fetchData();
    } catch (error) {
      setShowAlert(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <FormControl required error={showDatePrompt} variant="standard">
        <FormHelperText sx={{ ml: "2px", mb: "16px" }}>
          {showDatePrompt ? "Please choose your start date and end date." : " "}
        </FormHelperText>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <DatePicker
                autoFocus
                label="Start Date"
                value={startDate}
                onChange={handleChangeStartDate}
                maxDate={endDate}
              />
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleChangeEndDate}
                minDate={startDate}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lowtideOnly}
                    onChange={handleLowtideOnlyCheckbox}
                  />
                }
                label="Low Tide only"
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                disabled={disableSubmit}
                sx={{ mb: "25px" }}
                onClick={handleSubmit}
              >
                Tide Forecast
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      {alert && <Alert severity="error">Unable to fetch tide forecast!</Alert>}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ mt: 1 }}
      >
        <Grid item xs={4} sm={8} md={12}>
          {graphData && (
            <TidalGraph data={graphData} lowtideOnly={lowtideOnly} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TideForm;
