import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageContainer from "../layouts/PageContainer";
import { getBeachData } from "../utils/dataSourceUtil";
import BeachTable from "./Beach/BeachTable";

const Beach = () => {
  const [filteredBeachData, setFilteredBeachData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getBeachData();
        setFilteredBeachData(response);
      };
      fetchData();
    } catch (error) {
      setHasError(true);
    }
    setIsLoading(false);
  }, []);

  let dataLoaded = !isLoading && !hasError;

  return (
    <PageContainer>
      {isLoading ? <div>Data is loading...</div> : null}
      <Box>
        <Typography sx={{ fontWeight: "light", my: 2 }}>
          Using both data from WDFD and WA Department of Health, this map aims
          to make it easier to list out location of shellfish beaches and their
          status based on BOTH WDFW season and Harvest Safety (DOH). You can use
          the Search or Filter option to quickly locate a beach by name or
          county, or their current status.
        </Typography>

        {dataLoaded ? <BeachTable data={filteredBeachData} /> : null}
        {hasError ? <div>An error occurred while loading the data.</div> : null}
      </Box>
    </PageContainer>
  );
};

export default Beach;
