import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import useToggle from "../hooks/useToggle";
import PageContainer from "../layouts/PageContainer";

const Home = () => {
  const [visible, , setToggleStatus] = useToggle(false);

  const handleOpenDialog = useCallback(() => {
    setToggleStatus(true);
  }, [setToggleStatus]);

  const handleCloseDialog = useCallback(() => {
    setToggleStatus(false);
  }, [setToggleStatus]);

  // Sample photo URLs
  const photos = [
    { url: "/shellfish001.jpeg", caption: "Clamming @ Dosewallips" },
    { url: "/shellfish002.jpeg", caption: "Razor clams @ Twins Harbor" },
    { url: "/shellfish003.jpeg", caption: "Clamming @ Marrowstone Island" },
    { url: "/shellfish004.jpeg", caption: "Razor clams @ Long Beach" },
    {
      url: "/shellfish005.jpeg",
      caption: "Crabbing by Paddleboard is the best",
    },
    { url: "/shellfish006.jpeg", caption: "Mom dug her first razor clam" },
    { url: "/shellfish007.jpeg", caption: "Lots of clams @ Birch Bay" },
    { url: "/shellfish008.jpeg", caption: "Oystering @ Dosewallips" },
    { url: "/shellfish009.jpeg", caption: "Biggest crab I've caught" },
  ];

  return (
    <PageContainer title="Welcome to Shellfish Buddy">
      <Box>
        <Typography sx={{ fontWeight: "light", my: 1 }}>
          This project was born out of shellfish love for clamming, crabbing,
          and oystering. Geoducking TBD! What better way to enjoy the beautiful
          state of Washington by shellfishing, hiking, and camping.
        </Typography>
        <Typography sx={{ fontWeight: "light", my: 2 }}>
          Please note that this website is only meant to provide useful
          information to help you plan your shellfishing adventures. Always
          refer to official rules and guides from{" "}
          <a
            href="https://wdfw.wa.gov/fishing/shellfishing-regulations"
            target="_blank"
            rel="noreferrer"
          >
            WA Department of Fish & Wildlife
          </a>{" "}
          and{" "}
          <a
            href="https://fortress.wa.gov/doh/biotoxin/biotoxin.html"
            target="_blank"
            rel="noreferrer"
          >
            WA Department of Health
          </a>{" "}
          for latest updates.
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ mt: 1 }}
      >
        {photos.map((photo, index) => (
          <Grid item xs={4} key={index}>
            <Card style={{ maxWidth: 500 }}>
              <CardMedia
                image={photo.url}
                title="Cat Photo"
                style={{ height: "10em", paddingTop: "56.25%" }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "light" }}
                >
                  {photo.caption}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* <Box sx={{ maxWidth: "200px", marginTop: "64px" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleOpenDialog}
        >
          Shellfish Me!
        </Button>
      </Box>
      {visible && (
        <ShellfishDialog
          open={visible}
          onClose={handleCloseDialog}
          maxWidth="lg"
          title="Shellfish Form"
        />
      )} */}
    </PageContainer>
  );
};

export default Home;
