import { useState } from "react";
import BaseMap from "../Map/BaseMap";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { cityCoordinates } from "../../utils/constants";
import { CrabData } from "../../data/interfaces/CrabData";
import CrabbingLocationDetail from "./CrabbingLocationDetail";

interface CrabbingInfoProps {
  locations: CrabData[];
}

const CrabbingInfo = (props: CrabbingInfoProps) => {
  const { locations } = props;

  const [model] = useState({
    zoom: 10,
    center: cityCoordinates.SEATTLE,
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState<CrabData>();

  const handleMarkerClick = (
    id: string,
    lat: number,
    lng: number,
    location: CrabData
  ) => {
    setActiveMarker(id);
    setSelectedLocation(location);
  };

  return (
    <BaseMap model={model}>
      {locations.map((location) => {
        const { name, midx, midy } = location;
        const id = name;
        const lat = Number(midy);
        const long = Number(midx);

        return (
          <MarkerF
            key={id}
            title={id}
            position={{
              lat: lat,
              lng: long,
            }}
            onClick={() => handleMarkerClick(id, lat, long, location)}
          >
            {activeMarker === id && (
              <InfoWindowF
                key={id}
                onCloseClick={() => {
                  setActiveMarker(false);
                }}
              >
                {selectedLocation && (
                  <CrabbingLocationDetail location={selectedLocation} />
                )}
              </InfoWindowF>
            )}
          </MarkerF>
        );
      })}
    </BaseMap>
  );
};

export default CrabbingInfo;
