import Box from "@mui/material/Box";
import * as moment from "moment";
import MUIDataTable from "mui-datatables";
import { BeachData } from "../../data/interfaces/BeachData";
import { capitalizeFirstLetter, dateFormatter } from "../../utils/helpers";

interface BeachTableProps {
  data: BeachData[];
}

const columns = [
  {
    name: "beachId",
    label: "Beach ID",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "wdfwLink",
    label: "WDFW Link",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "beachName",
    label: "Beach Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => (
        <div>
          <a href={tableMeta.rowData[1]} target="_blank" rel="noreferrer">
            {capitalizeFirstLetter(value)}
          </a>
        </div>
      ),
    },
  },
  {
    name: "countyName",
    label: "County Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "finalStatus",
    label: "Final Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "clamSeason",
    label: "Clam Season Remark",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ),
    },
  },
  {
    name: "clamStartDate",
    label: "Clam Start Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <div>{dateFormatter(value, "MMM DD")}</div>,
    },
  },
  {
    name: "clamEnddate",
    label: "Clam End Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <div>{dateFormatter(value, "MMM DD")}</div>,
    },
  },
  {
    name: "oysterStartDate",
    label: "Oyster Start Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <div>{moment(value).format("MMM DD")}</div>,
    },
  },
  {
    name: "oysterEnddate",
    label: "Oyster End Date",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => <div>{moment(value).format("MMM DD")}</div>,
    },
  },
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "standard",
  pagination: true,
  download: false,
  viewColumns: false,
};

const BeachTable = (props: BeachTableProps) => {
  const { data } = props;
  if (data) {
    return (
      <Box sx={{ height: 800, width: "100%" }}>
        <MUIDataTable
          title={"Shellfish Location List"}
          data={data}
          columns={columns}
          options={options}
        />
      </Box>
    );
  }
};

export default BeachTable;
