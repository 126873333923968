import React from "react";
import PageContainer from "../layouts/PageContainer";
import { Typography, List, ListItem, ListItemText, Link } from "@mui/material";

const resources = [
  {
    id: 1,
    title: "NOAA",
    description: "NOAA Tide Predictions",
    link: [
      {
        id: 101,
        description: "CO-OPS API For Data Retrieval",
        link: "https://api.tidesandcurrents.noaa.gov/api/prod/",
      },
      {
        id: 102,
        description: "NOAA Tide Predictions UI",
        link: "https://tidesandcurrents.noaa.gov/noaatidepredictions.html?id=9447130",
      },
    ],
  },
  {
    id: 2,
    title: "Washington Department of Fish and Wildlife (WDFW)",
    description:
      "The WDFW is dedicated to preserving, protecting, and perpetuating the WA state fish, wildlife, and ecosystems. WDFW provides latest update on shellfishing rules and regulation, as well as toxin report for safe harvesting.",
    link: [
      {
        id: 103,
        description: "Shellfish Beaches",
        link: "https://wdfw.wa.gov/places-to-go/shellfish-beaches",
      },
      {
        id: 104,
        description: "ArcGIS REST Services",
        link: "https://fortress.wa.gov/doh/arcgis2/arcgis/rest/services/Biotoxin/Biotoxin_v4/MapServer/6",
      },
    ],
  },
  {
    id: 3,
    title: "Google Maps Platform",
    description: "Embed Google Maps to show all shellfish beaches and more",
    link: [
      {
        id: 105,
        description: "Google Maps Platform",
        link: "https://mapsplatform.google.com/",
      },
    ],
  },
];

const Resources = () => {
  return (
    <PageContainer title="Resources">
      <List>
        {resources.map((resource) => (
          <ListItem key={resource.id}>
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {resource.title}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant="body1" gutterBottom>
                    {resource.description}
                  </Typography>
                  {resource.link && (
                    <div>
                      {resource.link.map((linkItem) => (
                        <Typography variant="body1" key={linkItem.id}>
                          <Link
                            href={linkItem.link}
                            target="_blank"
                            rel="noopener"
                          >
                            {linkItem.description}
                          </Link>
                        </Typography>
                      ))}
                    </div>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </PageContainer>
  );
};

export default Resources;
