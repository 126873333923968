import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PageContainer from "../layouts/PageContainer";
import { getBeachData } from "../utils/dataSourceUtil";
import BeachMap from "./Map/BeachMap";

const Map = () => {
  const [filteredBeachData, setFilteredBeachData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);
  const [openOnly, setOpenOnly] = useState(false);

  const handleOpenOnlyCheckbox = (event) => {
    setOpenOnly(event.target.checked);
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await getBeachData();
        setFilteredBeachData(response);
      };
      fetchData();
    } catch (error) {
      setHasError(true);
    }
    setIsLoading(false);
  }, []);

  let dataLoaded = !isLoading && !hasError;

  return (
    <PageContainer title="Shellfish Locations 🌎">
      {isLoading ? <div>Data is loading...</div> : null}

      <Box>
        <Typography sx={{ fontWeight: "light", my: 1 }}>
          Using both data from WDFD and WA Department of Health, this map aims
          to make it easier to map out location of shellfish beaches and their
          status based on BOTH WDFW season and Harvest Safety (DOH). Click on
          specific beach location to verify their latest status on WDFD website.
          An interactive{" "}
          <a
            href="https://fortress.wa.gov/doh/biotoxin/biotoxin.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shellfish Safety Map
          </a>{" "}
          can also be found.
        </Typography>
        <Typography>
          <FormControlLabel
            control={
              <Checkbox checked={openOnly} onChange={handleOpenOnlyCheckbox} />
            }
            label="Open beaches only"
          />
        </Typography>

        {dataLoaded ? (
          <BeachMap locations={filteredBeachData} openOnly={openOnly} />
        ) : null}
        {hasError ? <div>An error occurred while loading the data.</div> : null}
      </Box>
    </PageContainer>
  );
};

export default Map;
