import * as moment from "moment";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
  Label,
} from "recharts";
import { TideData } from "../../data/interfaces/TideData";
import { dateFormatter } from "../../utils/helpers";
import "./TidalGraph.css";

const renderCustomAxisTick = (payload) => {
  return dateFormatter(payload, "M/D");
};

interface CustomTooltipProps {
  active: boolean;
  payload: unknown;
  label: string;
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload, label } = props;

  const data = moment(label, "YYYY-MM-DD HH:mm");

  return active ? (
    <div className="custom-tooltip">
      <p>{`${data.format("ddd")} ${data.format("MM/DD")} at ${data.format(
        "hh:mm A"
      )}`}</p>
      <p>
        <span className="toolTipWater">{`Water Level: `}</span>
        <span>{payload[0].payload.waterLevel}</span>
      </p>
    </div>
  ) : null;
};

interface CustomizedLabelProps {
  x: number;
  y: number;
}

const CustomizedLabel = (props: CustomizedLabelProps) => {
  const { x, y } = props;

  return <text x={x} y={y} dy={-4} fontSize={15} textAnchor="middle"></text>;
};

interface TidalGraphProps {
  data: TideData[];
  lowtideOnly: boolean;
}

const TidalGraph = (props: TidalGraphProps) => {
  const { data, lowtideOnly } = props;

  if (data) {
    // const filteredTides =
    //   data && lowtideOnly ? data.filter((tide) => tide.lowTide) : data;

    const filteredTides =
      data && lowtideOnly ? data.filter((tide) => tide.type === "L") : data;

    return (
      <ResponsiveContainer width="100%" aspect={1}>
        <LineChart
          data={filteredTides}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 40,
          }}
        >
          <Line
            type="monotone"
            dataKey="waterLevel"
            stroke="#8884d8"
            strokeLinejoin="round"
            activeDot={{ r: 8 }}
            label={<CustomizedLabel x={0} y={0} />}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            tickFormatter={renderCustomAxisTick}
            allowDataOverflow
            tickSize={10}
            tickMargin={10}
          >
            <Label
              value="Date"
              position="insideBottom"
              offset={-30}
              style={{ textAnchor: "middle" }}
            />
          </XAxis>
          <YAxis tickSize={10} tickMargin={10}>
            <Label
              value="Water Level"
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltip active={false} payload={undefined} label={""} />
            }
          />
          <ReferenceLine
            y={-0.5}
            stroke="green"
            strokeWidth={2}
            label={{
              position: "top",
              value: "Ideal Clam/Oyster Level",
              fill: "green",
              fontSize: 15,
            }}
          />
          <ReferenceLine
            y={-2}
            stroke="orange"
            strokeWidth={2}
            label={{
              position: "top",
              value: "Max Geoduck Level",
              fill: "orange",
              fontSize: 15,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  } else {
    return null;
  }
};

export default TidalGraph;
